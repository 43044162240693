var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('page-header',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"card-box"},[_c('awesome-table',{attrs:{"id":"fedex-batches","labels":_vm.labels,"items":_vm.batches,"options":{
                addButton: false,
                sort: true,
                filters: true,
                columnsSelector: true,
                csvExport: false,
                isCard: false
            },"pagination":_vm.pagination,"is-loading":_vm.isLoading,"filters":_vm.filters},on:{"pagination-change":_vm.onPaginationChange,"selection-change":_vm.onSelectionChange,"filter-change":_vm.onFilterChange},scopedSlots:_vm._u([{key:"items.user.first_name",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n                "+_vm._s(((item.user.first_name) + "\n                    " + (item.user.last_name)))+"\n            ")]}},{key:"items.instructions_file",fn:function(ref){
                    var item = ref.item;
return [_c('a',{staticClass:"btn btn-xs btn-info",attrs:{"href":_vm.getGroupInstructionLink(item),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("\n                    Instructions\n                ")])]}},{key:"items.fedex_labels",fn:function(ref){
                    var item = ref.item;
return [(item.fedex_label)?_c('button',{staticClass:"btn btn-xs btn-info",on:{"click":function($event){$event.stopPropagation();return _vm.getFedexDocument(item.fedex_label)}}},[_c('i',{staticClass:"fe-download mr-1"}),_vm._v("\n                    Labels\n                ")]):_vm._e()]}},{key:"items.fedex_invoices",fn:function(ref){
                    var item = ref.item;
return [(item.fedex_invoice)?_c('button',{staticClass:"btn btn-xs btn-info ml-1",on:{"click":function($event){$event.stopPropagation();return _vm.getFedexDocument(item.fedex_invoice)}}},[_c('i',{staticClass:"fe-download mr-1"}),_vm._v("\n                    Invoices\n                ")]):_vm._e()]}},{key:"items.status",fn:function(ref){
                    var value = ref.value;
return [_c('span',{class:_vm.getBadgeClass(value)},[_vm._v("\n                    "+_vm._s(value)+"\n                ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
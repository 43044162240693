<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div class="card-box">
            <awesome-table
                id="fedex-batches"
                :labels="labels"
                :items="batches"
                :options="{
                    addButton: false,
                    sort: true,
                    filters: true,
                    columnsSelector: true,
                    csvExport: false,
                    isCard: false
                }"
                :pagination="pagination"
                :is-loading="isLoading"
                :filters="filters"
                @pagination-change="onPaginationChange"
                @selection-change="onSelectionChange"
                @filter-change="onFilterChange"
            >
                <template #[`items.user.first_name`]="{ item }">
                    {{
                        `${item.user.first_name}
                        ${item.user.last_name}`
                    }}
                </template>
                <template #[`items.instructions_file`]="{ item }">
                    <a
                        :href="getGroupInstructionLink(item)"
                        target="_blank"
                        class="btn btn-xs btn-info"
                        @click.stop
                    >
                        Instructions
                    </a>
                </template>
                <template #[`items.fedex_labels`]="{ item }">
                    <button
                        v-if="item.fedex_label"
                        class="btn btn-xs btn-info"
                        @click.stop="getFedexDocument(item.fedex_label)"
                    >
                        <i class="fe-download mr-1" />
                        Labels
                    </button>
                </template>
                <template #[`items.fedex_invoices`]="{ item }">
                    <button
                        v-if="item.fedex_invoice"
                        class="btn btn-xs btn-info ml-1"
                        @click.stop="getFedexDocument(item.fedex_invoice)"
                    >
                        <i class="fe-download mr-1" />
                        Invoices
                    </button>
                </template>
                <template #[`items.status`]="{ value }">
                    <span :class="getBadgeClass(value)">
                        {{ value }}
                    </span>
                </template>
            </awesome-table>
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import config from '@/config';
import appConfig from '@src/app.config';

export default {
    page: {
        title: 'Fedex Batches',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    data() {
        const {
            page: currentPage = 1,
            perPage = 10,
            sortBy = 'created_at',
            order = 'DESC',
            q = null,
            filters = '{}'
        } = this.$route.query;

        return {
            title: 'Fedex Batches',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Fedex Batches',
                    active: true
                }
            ],
            labels: [
                {
                    text: 'Batch Id',
                    value: 'batch_number'
                },
                {
                    text: 'User Name ',
                    value: 'user.first_name',
                    sortable: false
                },
                {
                    text: 'Items Count ',
                    value: 'orderProductColorShipments.length',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'instructions_file',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'fedex_labels',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'fedex_invoices',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'status',
                    type: 'enum',
                    enumOptions: [
                        'PROCESSING',
                        'COMPLETED',
                        'PARTIALLY COMPLETED',
                        'FAILED'
                    ]
                },
                {
                    value: 'created_at',
                    filter: 'formatDate',
                    type: 'date'
                }
            ],
            batches: [],
            pagination: {
                currentPage: parseInt(currentPage),
                perPage: parseInt(perPage),
                total: 0,
                search: q || '',
                sortBy,
                descending: order.toLowerCase() === 'desc'
            },
            filters: JSON.parse(filters),
            selected: [],
            isLoading: false,
            appUrl: config.url
        };
    },

    async created() {
        this.fetchBatches();
    },

    methods: {
        ...mapActions({
            getBatches: 'batches/index'
        }),

        async fetchBatches() {
            this.isLoading = true;

            try {
                const options = {
                    ...this.pagination,
                    filters: this.filters
                };

                const { rows, count } = await this.getBatches(options);

                if (options.currentPage !== this.pagination.currentPage) {
                    return;
                }

                this.batches = rows;
                this.pagination.total = count;
                this.pagination.pages = Math.ceil(
                    this.pagination.total / this.pagination.perPage
                );
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }

            this.isLoading = false;
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };

            this.fetchBatches();
        },

        onSelectionChange(items) {
            this.selected = [...items];
        },

        onFilterChange(filters) {
            this.filters = { ...filters };
            this.pagination.currentPage = 1;

            this.fetchBatches();
        },

        getBadgeClass(value) {
            let color = 'success';

            if (value === 'PROCESSING') {
                color = 'warning';
            } else if (value === 'FAILED') {
                color = 'danger';
            }

            return `badge badge-${color}`;
        },

        getGroupInstructionLink(batch) {
            const ids = batch.orderProductColorShipments.map(
                ({ orderProductColor }) => orderProductColor.id
            );
            const queryIds = ids.join(',');

            return `${
                this.appUrl
            }/factory-data/group-instructions?ids=${queryIds}`;
        },

        getFedexDocument(fileName) {
            const url = `${config.apiUrl}/fedex_documents/${fileName}`;

            const fileLink = document.createElement('a');
            fileLink.href = url;
            fileLink.setAttribute('download', `${fileName}.pdf`);
            fileLink.setAttribute('target', `_blank`);
            document.body.appendChild(fileLink);
            fileLink.click();
        }
    }
};
</script>

<style lang="scss">
.vs__dropdown-menu {
    min-width: 200px;
}
</style>
